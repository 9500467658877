import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import BasicTable from '../general/Table.js';
import ConfirmationModal from 'components/general/ConfirmationModal';
import 'components/routes/FileManager.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faImage, faFile, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { getBaseURL, getFileURL } from 'apiUtils';
import { useNotification } from '../context/NotificationContext';


const FileManager = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileDetails, setSelectedFileDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const fileURL = getFileURL()
  const baseURL = getBaseURL()
  const { showMessage } = useNotification();

  const StagedFileHeaders = [
    {
      id: 'type',
      label: '',
      renderCell: (row) => (
        <FontAwesomeIcon icon={row.type === "image/jpeg" || row.type === "image/png" ? faImage : faFile} />
      ),
    },
    { id: 'name', label: 'Name' },
  ];

  const FileTableHeaders = [
    {
      id: 'type',
      label: '',
      renderCell: (row) => {
        const fileType = row.file_name.split('.').pop();
        return <FontAwesomeIcon icon={fileType === "jpeg" || fileType === "png" ? faImage : faFile} />;
      },
    },
    { id: 'file_name', label: 'Name' },
    { id: 'uploaded_at', label: 'Upload Date' },
    { id: 'uploaded_by', label: 'Uploaded By' },
    {
      id: 'download',
      label: '',
      renderCell: (row) => (
        <Button variant="contained" color="primary" onClick={() => window.open(row.download, '_blank')}>
          Download
        </Button>
      ),
    },
    {
      id: 'delete',
      label: '',
      renderCell: (row, index) => (
        <Button variant="contained" color="error" onClick={() => showDeleteConfirmation(row.id)}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      ),
    },
  ];

  const PaginationOptions = [10, 25, 50];

  const fetchFiles = useCallback(async () => {
    try {
      const response = await axios.get(`${baseURL}/api/v2/file/`, { withCredentials: true });
      const parsedFiles = response.data.map((entry) => ({
        id: entry.id,
        file_name: entry.file_name,
        uploaded_at: new Date(entry.uploaded_at).toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
        uploaded_by: entry.uploaded_by,
        download: `${fileURL}${entry.path}`,
      }));
      setFiles(parsedFiles);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        showMessage('Security Credentials Insufficient', 'error');
      }
      console.error('Error fetching files', error);
    }
  }, [baseURL, fileURL]);

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => prevFiles.concat(filesArray));
    const fileDetails = filesArray.map(file => ({ name: file.name, size: parseFloat((file.size / 1000000)).toFixed(1), type: file.type }));
    setSelectedFileDetails((prevDetails) => prevDetails.concat(fileDetails));
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    selectedFiles.forEach(file => formData.append('files', file));
    setLoading(true);

    try {
      const csrfToken = Cookies.get('csrftoken');
      await axios.post(`${baseURL}/api/v2/file/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': csrfToken,
        },
        withCredentials: true,
      });
      fetchFiles();
      setSelectedFiles([]);
      setSelectedFileDetails([]);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        showMessage('Security Credentials Insufficient', 'error');
      }
      console.error('Error uploading files', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const handleUnstageFiles = () => {
    setSelectedFiles([]);
    setSelectedFileDetails([]);
  };

  const showDeleteConfirmation = (id) => {
    setFileToDelete(id);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      const csrfToken = Cookies.get('csrftoken');
      await axios.delete(`${baseURL}/api/v2/file/${fileToDelete}/`, {
        headers: { 'X-CSRFToken': csrfToken },
        withCredentials: true,
      });
      fetchFiles();
      setIsModalOpen(false);
      setFileToDelete(null);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        showMessage('Security Credentials Insufficient', 'error');
      }
      console.error('Error deleting file', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="file-manager-container">
      <div className='file-uploads-container'>
        <input
          type="file"
          id="fileInput"
          multiple
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <h3>Select Files</h3>
        <label htmlFor="fileInput" className='browse-button'>
          <div>Click Here to Browse Files</div>
        </label>
        <div className='table-container'>
          <BasicTable columns={StagedFileHeaders} data={selectedFileDetails} rowsPerPageOptions={100} disableHeader={true} disablePagination={true} className="uploaded-files-table" />
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button variant="contained" color="primary" onClick={handleFileUpload} disabled={selectedFiles.length === 0}>
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faFileUpload} />}
          </Button>
          <Box width={"12px"} />
          <Button variant="contained" color="warning" onClick={handleUnstageFiles} disabled={selectedFiles.length === 0}>
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faTrash} />}
          </Button>
        </div>
      </div>
      <div className="uploads-table-container">
        <h3 className="file-manager-title">Uploaded Files</h3>
        <div className='table-container'>
          <BasicTable columns={FileTableHeaders} data={files} rowsPerPageOptions={PaginationOptions} className="uploaded-files-table" disablePagination={true} />
        </div>
      </div>
      <ConfirmationModal
        headingText="Confirm Deletion"
        subHeadingText="Are you sure you want to delete this file?"
        closeText="CANCEL"
        confirmText="DELETE"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </div >
  );
};

export default FileManager;
