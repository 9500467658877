import './MenuItems.css';

import Logout from '../logout/Logout';

import { appEndpoints } from '../../config/Endpoints';
import { useNavigate } from "react-router-dom";

const MenuItems = () => {
    const navigate = useNavigate();

    const menuItemsList = [
        {
            title: 'Dashboard',
            url: appEndpoints.dashboard,
        },
        {
            title: 'User Manual',
            url: appEndpoints.userManual,
        },
        {
            title: 'Reporting',
            url: appEndpoints.reporting,
        },
        {
            title: 'Flows',
            url: appEndpoints.flowConfiguration,
        },
        {
            title: 'Alerts',
            url: appEndpoints.alert,
        },
        {
            title: 'Rule Engine',
            url: appEndpoints.ruleEngine,
        }
    ]; return (
        <ul className="menu-items-list">
            <button onClick={() => window.location = appEndpoints.controlPanel} className="link-button">
                Control Panel
            </button>
            {menuItemsList.map((menuItem, index) => (
                <button onClick={() => navigate(menuItem.url)} className="link-button">
                    {menuItem.title}
                </button>
            ))}
            <li>
                <Logout />
            </li>
        </ul>
    );
};

export default MenuItems;
