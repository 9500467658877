const tenant = window.location.hostname.split('.')[0];
const apiBaseUrl = process.env.REACT_APP_API_ENDPOINT.replace('*', tenant);

// Define and export endpoints
const appEndpoints = {
  login: `/login/`,
  controlPanel: `${apiBaseUrl}/zone_management/`,
  dashboard: `/dashboard/`,
  reporting: `/reporting/`,
  userManual: `/file-manager/`,
  flowConfiguration: '/flows/',
  alert: '/alert/',
  ruleEngine: `/rule-engine/`,
};

const apiEndpoints = {
  logout: `${apiBaseUrl}/api/v2/logout/`,
  devices: `${apiBaseUrl}/api/devices/`,
  deviceConfig: `${apiBaseUrl}/api/v2/device/config/`,
  deviceParameters: `${apiBaseUrl}/api/device_state_node_b/`,
  legacySensors: `${apiBaseUrl}/api/sensors/`,
  reportSettingEndpoint: `${apiBaseUrl}/api/v2/report/setting/`,
  legacyReportSettingEndpoint: `${apiBaseUrl}/api/sensor_report_setting/`,
  flows: `${apiBaseUrl}/api/v2/flow/`,
  tenantManagementEndpoint: `${apiBaseUrl}/api/v2/tenant/`,
  userManagementEndpoint: `${apiBaseUrl}/api/users/`,
  AlertEndpoint: `${apiBaseUrl}/api/v2/alert/`,
  ruleEngineEndpoint: `${apiBaseUrl}/api/v2/rule_chain/`,
  ruleEngineDevicesDataEndpoint: `${apiBaseUrl}/api/device/rule_engine/parameters/`,
};

export { appEndpoints, apiEndpoints };
