import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import './DimmerDevice.css'
import { useNotification } from '../../context/NotificationContext';

const DimmerDevice = ({ device_id, device_name }) => {
    const [brightness, setBrightness] = useState(0);
    const [loading, setLoading] = useState(true); // Start as true for the initial load

    // Get tenant, use to create API endpoint
    const tenant = window.location.hostname.split(".")[0]
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const baseUrl = apiUrl.replace("*", tenant)
    const { showMessage } = useNotification();

    useEffect(() => {
        const fetchDeviceState = async () => {
            try {
                $.ajax({
                    url: `${baseUrl}/api/device_state/${device_id}/`,
                    method: 'GET',
                    xhrFields: {
                        withCredentials: true,
                    },
                    success: function (response) {
                        setBrightness(Math.round(response.level * (8 / 255)));
                        setLoading(false); // Set loading to false upon successful fetch
                    },
                    error: function (error) {
                        if (error.status === 403) {
                            showMessage('Security Credentials Insufficient', 'error');
                        }
                        console.error('Error fetching device state.', error);
                        setLoading(false); // Ensure loading is set to false even on error
                    }
                });
            } catch (error) {
                console.error('Error:', error);
                setLoading(false); // Ensure loading is set to false on catch
            }
        };

        fetchDeviceState(); // Fetch immediately on mount

        // Setup interval for fetching every 15 seconds
        const intervalId = setInterval(fetchDeviceState, 15000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [device_id, baseUrl]);

    const renderDimmerLevels = () => {
        let levelsArray = [];
        for (let i = 8; i >= 0; i--) {
            const levelClass = brightness === i ? 'dimmer-lit' : 'dimmer-off';
            levelsArray.push(
                <div key={i}
                    className={`dimmer-dot ${levelClass}`}>
                    {/* onClick={() => handleDimmerClick(i)}> */}
                </div>
            );
        }
        return levelsArray;
    };

    return (
        <fieldset className="widget-container" style={{ height: "auto" }}>
            <div className="widget-title">{device_name}</div>
            <div className="dimmer-levels">
                {renderDimmerLevels()}
            </div>
            {loading && <div className='loading'>Loading...</div>}
        </fieldset >
    );
};

export default DimmerDevice;
