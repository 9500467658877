// React and third-party libraries
import React, { useState, useEffect, Fragment } from 'react';
import {
    Grid,
    Button,
    FormControl,
    Typography,
    FormControlLabel,
    Box,
    Checkbox,
    TextField,
    MenuItem,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import Chart from '../widgets/chart_widgets/Chart';
import DeviceSelector from '../general/DeviceSelector';
import ParameterSelector from '../general/ParameterSelector';
import IntervalSelector from '../general/IntervalSelector';
import RangeSelector from '../general/RangeSelector';
import Wizard from '../general/Wizard';
import MultiSelectDropdown from '../general/MultiSelectDropdown';

// Configurations
import {apiEndpoints, appEndpoints} from '../../config/Endpoints';

// Data hooks
import useDevices from '../../hooks/Devices';
import useDeviceParameters from '../../hooks/Parameters';
import useSaveRangeSetting from '../../hooks/ReportSetting';
import useFetchItems from '../../hooks/FetchItems';

// Context
import { useNotification } from '../context/NotificationContext';

// Styles
import './Reporting.css'


const Reporting = () => {
    const { showMessage } = useNotification();
    const [error, setError] = useState({});
    const [usersDropdownData, setUsersDropdownData] = useState([]);
    const devices = useDevices();
    const [device, setDevice] = useState({ 'key': '', 'id': 0, 'name': 'No device selected', 'type': ''});
    const parameters = useDeviceParameters(device);
    const [parameter, setParameter] = useState({ 'id': '', 'name': 'No parameter selected', 'attribute': ''});
    const [reportingInterval, setReportingInterval] = useState({'name': 'Daily', 'hrs': 24});
    const [reportingIntervals] = useState([
        {'name': 'Daily', 'hrs': 24},
        {'name': 'Weekly', 'hrs': 24 * 7},
        {'name': 'Monthly', 'hrs': 24 * 30},
    ]);

    const [periodicReportingIntervals] = useState([
        {'name': 'Daily', 'hrs': 24},
        {'name': 'Weekly', 'hrs': 24 * 7},
        {'name': 'Monthly', 'hrs': 24 * 30},
    ]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [reportSetting, setReportSetting] = useState({});
    const [rangeUpdate, setRangeUpdate] = useState(false);
    const [showRange, setShowRange] = useState(false);
    const {isReportRangeLoading, saveRange} = useSaveRangeSetting();

    const userEndpoint = apiEndpoints.userManagementEndpoint;

    const [isWizardOpen, setWizardOpen] = useState(false);
    const [refetchIndex, setRefetchIndex] = useState(0);
    const [idToEdit, setIdToEdit] = useState(0);

    const { data: usersData, count: usersCount } = useFetchItems(userEndpoint, 0, 1000, refetchIndex);

    useEffect(() => {
        setUsersDropdownData(
            usersData.map(user => ({
                id: user.id,
                name: user.username,
                selected: false
            }))
        )
    }, [usersData]);


    const handleDeviceChange = (value) => {
        const selectedDevice = devices.find(device => device.key === value);

        setDevice({
            'key': selectedDevice.key,
            'id': selectedDevice.id,
            'name': selectedDevice.device_name,
            'type': selectedDevice.device_type
        });
        resetParameter();
    };

    const handleParameterChange = (value) => {
        const selectedParameter = parameters.find(parameter => parameter.id === value);
        setParameter({
            'id': value,
            'name': selectedParameter.name,
            'attribute': selectedParameter.attribute
        });
    };

    const handleReportingIntervalChange = (value) => {
        const selectedReportingInterval = reportingIntervals.find(interval => interval.hrs === value);

        setReportingInterval(selectedReportingInterval);
    };

    const resetParameter = () => {
        setParameter({ 'id': '', 'name': 'No parameter selected', 'attribute': ''});
    }

    const handleMinValue = (value) => {
        setReportSetting(prevSettings => ({
            ...prevSettings,
            [parameter.id]: {
                ...prevSettings[parameter.id],
                min: parseInt(value),
            }
        }));
    };

    const handleMaxValue = (value) => {
        setReportSetting(prevSettings => ({
            ...prevSettings,
            [parameter.id]: {
                ...prevSettings[parameter.id],
                max: parseInt(value),
            }
        }));
    };

    const handleIsEmailReport = (value) => {
        setReportSetting(prevSettings => ({
            ...prevSettings,
            [parameter.id]: {
                ...prevSettings[parameter.id],
                is_email_report: value,
            }
        }));
    };

    const handleReportInterval = (value) => {
        setReportSetting(prevSettings => ({
            ...prevSettings,
            [parameter.id]: {
                ...prevSettings[parameter.id],
                report_interval: value,
            }
        }));
    };

    const handleReportTitle = (value) => {
        setReportSetting(prevSettings => ({
            ...prevSettings,
            [parameter.id]: {
                ...prevSettings[parameter.id],
                report_title: value,
            }
        }));
    };

    const handleEmailMessage = (value) => {
        setReportSetting(prevSettings => ({
            ...prevSettings,
            [parameter.id]: {
                ...prevSettings[parameter.id],
                email_message: value,
            }
        }));
    };

    const handleOpen = () => {
        setIsSubmit(false);
        setWizardOpen(true);
    };

    const handleClose = () => {
        setWizardOpen(false);
    };

    const submitReportSettings = async () => {
        console.log(reportSetting)

        try {
            await saveRange(device.id, device.type, parameter.id, reportSetting);
            handleClose();
            showMessage(`Report settings saved successfully`, 'success');
        } catch (error) {
            // Handle error if needed
        }
    };

    const handleFinish = () => {
        setRangeUpdate(true);
        submitReportSettings();
        setIsSubmit(true);
    };

    const handleUsers = (event) => {
        const {
          target: { value },
        } = event;

        setReportSetting(prevSettings => ({
            ...prevSettings,
            [parameter.id]: {
                ...prevSettings[parameter.id],
                users: value
            }
        }));
    };

    useEffect(() => {
        if (device.type === 'Sensor' && parameter.id in reportSetting) {
            if ('min' in reportSetting[parameter.id] && 'max' in reportSetting[parameter.id]){
                setShowRange(true);
            } else{
                setShowRange(false);
            }
            if ('users' in reportSetting[parameter.id]){
                setUsersDropdownData((prevFields) =>
                  prevFields.map((field) =>
                    reportSetting[parameter.id]['users'].includes(field.id)
                      ? { ...field, selected: true }
                      : { ...field, selected: false }
                  )
                );
            } else {
                setUsersDropdownData(
                    usersData.map(user => ({
                        id: user.id,
                        name: user.username,
                        selected: false
                    }))
                )
            }
        }
    }, [reportSetting, parameter]);

    const steps = [
        ...(showRange
            ? [
                {
                  label: 'Setup Range',
                  content: () => (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        {reportSetting[parameter.id] && 'min' in reportSetting[parameter.id] && (
                            <RangeSelector
                              label="Range Min"
                              value={reportSetting[parameter.id]['min']}
                              onRangeChange={handleMinValue}
                            />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        {reportSetting[parameter.id] && 'max' in reportSetting[parameter.id] && (
                            <RangeSelector
                              label="Range Max"
                              value={reportSetting[parameter.id]['max']}
                              onRangeChange={handleMaxValue}
                            />
                        )}
                      </Grid>
                    </Grid>
                  ),
                },
              ]
            : []),
        {
            label: 'Email Reports',
            content: () => (
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ mb: 10 }}>
                            Enable email reporting for the selected parameter of the device.
                        </Typography>
                        <Box marginBottom={2}>
                            {reportSetting[parameter.id] && 'is_email_report' in reportSetting[parameter.id] && (
                                <FormControlLabel
                                    control={
                                            <Checkbox
                                                checked={reportSetting[parameter.id]['is_email_report']}
                                                onChange={(e) => handleIsEmailReport(e.target.checked)}
                                            />
                                    }
                                    label="Enable email periodic report"
                                />
                            )}
                        </Box>
                        {reportSetting[parameter.id] && reportSetting[parameter.id]['is_email_report'] && (
                            <>

                                <Box marginBottom={2}>
                                    {reportSetting[parameter.id] && 'report_interval' in reportSetting[parameter.id] && (
                                        <TextField
                                            select
                                            fullWidth
                                            label='Periodic Reporting Interval'
                                            value={reportSetting[parameter.id]['report_interval']}
                                            onChange={e => handleReportInterval(e.target.value)}
                                            required
                                        >
                                            {periodicReportingIntervals.map(interval => (
                                                <MenuItem key={interval.hrs} value={interval.hrs}>
                                                    {interval.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                </Box>
                                <Box marginBottom={2}>
                                    {reportSetting[parameter.id] && 'report_title' in reportSetting[parameter.id] && (
                                        <TextField
                                            label="Title of Report"
                                            fullWidth
                                            variant="outlined"
                                            value={reportSetting[parameter.id]['report_title']}
                                            onChange={(e) => handleReportTitle(e.target.value)}
                                            required
                                        />
                                    )}
                                </Box>
                                <Box marginBottom={2}>
                                    {reportSetting[parameter.id] && 'email_message' in reportSetting[parameter.id] && (
                                        <TextField
                                            label="Email Message"
                                            fullWidth
                                            variant="outlined"
                                            value={reportSetting[parameter.id]['email_message']}
                                            onChange={(e) => handleEmailMessage(e.target.value)}
                                            required
                                        />
                                    )}
                                </Box>
                                <Box marginBottom={2}>
                                    {reportSetting[parameter.id] && 'users' in reportSetting[parameter.id] && (
                                        <MultiSelectDropdown
                                            label="Select Users"
                                            itemsData={usersDropdownData}
                                            selectedItems={reportSetting[parameter.id]['users']}
                                            handleChange={handleUsers}
                                        />
                                    )}
                                </Box>
                            </>
                        )}
                    </Grid>
                </Grid>
            )
        }
    ];

    return (
        <Grid container className="grid-container" spacing={2}>

            <Wizard
                open={isWizardOpen}
                steps={steps}
                error={error}
                isSubmit={isSubmit}
                handleClose={() => handleClose()}
                handleFinish ={() => handleFinish()}
            />

            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <DeviceSelector
                    devices={devices}
                    selectedDeviceKey={device.key}
                    onDeviceChange={handleDeviceChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <ParameterSelector
                    parameters={parameters}
                    selectedParameterId={parameter.id}
                    onParameterChange={handleParameterChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <IntervalSelector
                    label={"Reporting Interval"}
                    Intervals={reportingIntervals}
                    selectedIntervalHrs={reportingInterval.hrs}
                    onIntervalChange={handleReportingIntervalChange}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={1} xl={1}>
                <Button
                    variant="contained"
                    className="save-range"
                    onClick={handleOpen}
                    fullWidth
                    disabled={!device.id || !parameter.id || (device.type !== 'Sensor' && !(parameter.id in reportSetting))}
                >
                    Settings
                </Button>
            </Grid>
            <Grid item className="reporting-container" xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="reporting-container">
                    <Chart
                        device={device}
                        parameter={parameter}
                        interval={reportingInterval}
                        reportSetting={reportSetting}
                        setReportSetting={setReportSetting}
                        rangeUpdate={rangeUpdate}
                    />
                </div>
            </Grid>

        </Grid>
    );
};

export default Reporting;
