// React and third-party libraries
import React from 'react';

// Components
import SensorChart from './SensorChart';
import DeviceChart from './DeviceChart';

const Chart = ({ device, parameter, interval, reportSetting, setReportSetting, setShowRange, rangeUpdate }) => {
    let boardType = '';
    let paramId = parameter.id;
    let attributeId = '';

    if (paramId.includes(':')) {
        [boardType, paramId] = parameter.id.split(':');
        attributeId = parameter.attribute
    }

    if (device.type === 'LegacySensor') {
        return <SensorChart
            chart_id={1}
            sensor_id={device.id}
            sensor_name={`${device.name}: ${parameter.name}`}
            reporting_period={interval.hrs}
            data_type={paramId}
            reportSetting={reportSetting}
            setReportSetting={setReportSetting}
            setShowRange={setShowRange}
            rangeUpdate={rangeUpdate}
        />;
    }else {
        return <DeviceChart
            chart_id={1}
            device_id={device.id}
            device_name={`${device.name}: ${parameter.name}`}
            device_type={device.type}
            reporting_period={interval.hrs}
            board_type={boardType}
            attribute={attributeId}
            parameter={paramId}
            reportSetting={reportSetting}
            setReportSetting={setReportSetting}
            setShowRange={setShowRange}
            rangeUpdate={rangeUpdate}
        />;
    }
};

export default Chart;
