import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Chip, Box } from '@mui/material';

function MultiSelectDropdown({ label, itemsData, selectedItems, handleChange, disabled=false }) {
  return (
    <FormControl fullWidth>
      <InputLabel id="multi-select-dropdown-label">{label}</InputLabel>
      <Select
        labelId="multi-select-dropdown-label"
        id="multi-select-dropdown"
        multiple
        value={selectedItems}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Select Items" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        disabled={disabled}
      >
        {itemsData.map((item) => (
          <MenuItem
            key={item.id}
            value={item.name}  // using item.name as value for the sake of display and uniqueness
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelectDropdown;
