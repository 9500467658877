import React, { useState, useEffect, useCallback } from 'react';
import $ from 'jquery'

import 'App.css'
import './AlertsTable.css';
import { useNotification } from '../../context/NotificationContext';

const AlertsTable = ({ alert_types }) => {
    const [staticAlerts, setStaticAlerts] = useState([]);
    const [dynamicAlerts, setDynamicAlerts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true); // Track initial loading

    // Get tenant, use to create API endpoint
    const tenant = window.location.hostname.split(".")[0]
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const baseUrl = apiUrl.replace("*", tenant)
    const { showMessage } = useNotification();

    const fetchAlerts = useCallback(async () => {
        if (initialLoad) setLoading(true); // Only show loading on initial load
        try {
            if (alert_types.includes("static")) {
                // Fetch static alerts
                let staticQueryParams = new URLSearchParams({ page: 1 });
                const staticDataUrl = `${baseUrl}/api/alerts?${staticQueryParams.toString()}`;
                const staticResponse = await $.ajax({
                    url: staticDataUrl,
                    method: 'GET',
                    xhrFields: { withCredentials: true },
                });
                setStaticAlerts(staticResponse);
            }

            if (alert_types.includes("dynamic")) {
                // Fetch dynamic alerts
                const dynamicDataUrl = `${baseUrl}/api/v2/dynamic/alert`;
                const dynamicResponse = await $.ajax({
                    url: dynamicDataUrl,
                    method: 'GET',
                    xhrFields: { withCredentials: true },
                });
                setDynamicAlerts(dynamicResponse);
            }

        } catch (error) {
            if (error.status === 403) {
                showMessage('Security Credentials Insufficient', 'error');
            }
        } finally {
            if (initialLoad) {
                setLoading(false);
                setInitialLoad(false); // Ensure loading is only shown on initial fetch
            }
        }
    }, [baseUrl, initialLoad, alert_types]);

    // useEffect hook for fetching device data from the API
    useEffect(() => {
        fetchAlerts(); // Fetch immediately on mount
        const interval = setInterval(fetchAlerts, 15000); // Fetch every 15 seconds
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [fetchAlerts]);

    const combineAndNormalizeAlerts = () => {
        const normalizedStaticAlerts = staticAlerts.map(alert => ({
            ...alert,
            acknowledged: alert.aknowledged, // Correct the typo by mapping to a consistent property name
            alert_type: alert.alert_type,
            created_at: alert.created_at,
        }));

        // Combine all alerts
        const combinedAlerts = [...dynamicAlerts, ...normalizedStaticAlerts];

        // Filter alerts where acknowledged is 'no'
        const unacknowledgedAlerts = combinedAlerts.filter(alert => alert.acknowledged === false);

        // Sort unacknowledged alerts by the created_at field
        return unacknowledgedAlerts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    };


    // Utility function to format datetime string
    function formatDate(datetimeStr) {
        const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(datetimeStr);
        const formattedDate = date.toLocaleString('en-US', options);

        // Extracting components from the formattedDate
        // Since the output format could include commas, especially after the day, we remove them
        let [monthDay, time,] = formattedDate.split(', ');
        let [month, day] = monthDay.split(' ');
        day = day.replace(',', ''); // Ensure no trailing comma
        time = time.replace('AM', "")
        time = time.replace('PM', "")

        // Constructing the final string without the comma after the day
        return `${time} ${month} ${day}`;
    }

    const renderAlertsTable = () => {
        const combinedAlerts = combineAndNormalizeAlerts();
        return (
            <table className='alerts-table'>
                <thead className='table-header'>
                    <tr>
                        <th className='text-bold'>Event</th>
                        <th className='text-bold'>Device</th>
                        <th className='text-bold'>Time</th> {/* Add a header for Created At */}
                    </tr>
                </thead>
                <tbody>
                    {combinedAlerts.map((alert, index) => (
                        <tr key={`${alert.id}-${index}`}>
                            <td className='text-light'>{alert.event}</td>
                            <td className='text-light'>{alert.device}</td>
                            <td className='text-light'>{formatDate(alert.created_at)}</td> {/* Format and display the created_at field */}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className='widget-container'>
            <div className='widget-title'>
                Alerts Table
            </div>
            <div className="table-scroll">
                {loading ? <p>Loading...</p> : renderAlertsTable()}
            </div>
        </div>

    );
};

export default AlertsTable;