// React and third-party libraries
import { useState, useEffect } from 'react';

// Configurations
import { apiEndpoints, appEndpoints } from '../config/Endpoints';
import {legacyParameters} from '../config/Parameters';
import { useNotification } from '../components/context/NotificationContext';


const useDeviceParameters = (device) => {
    const [parameters, setParameters] = useState([]);
    const { showMessage } = useNotification();


    useEffect(() => {
        if (!device.id) return;

        const fetchParameters = async () => {
            let endpoint;
            let extractor;

            switch (device.type) {
                case 'Node B':
                    endpoint = apiEndpoints.deviceParameters;
                    extractor = extractParametersNodeB;
                    break;
                case 'Sensor':
                    endpoint = apiEndpoints.deviceConfig;
                    extractor = extractV2DevicesParameters;
                    break;
                default:
                    if (device.type in legacyParameters) {
                        setParameters(getParametersLegacyDevices(device));
                        return;
                    }
                    return;
            }

            try {
                const response = await fetch(endpoint, {
                    method: 'GET',
                    credentials: 'include'
                });
                if (!response.ok) {
                    if (response.status === 403) {
                        showMessage('Security Credentials Insufficient', 'error');
                    }
                    throw new Error('Failed to fetch parameters');
                }
                const data = await response.json();
                setParameters(extractor(device.type === 'Sensor' ? data : data.results, device));
            } catch (error) {
                console.error("Fetch error: ", error);
            }
        };

        fetchParameters();
    }, [device]);

    return parameters;
};


const extractParametersNodeB = (data,  device) => {
    let _parameters = [];
    const filteredData = data.filter(item => item.device_id === device.id);

    // Node B parameters
    filteredData.forEach(item => {
        const boardType = item.board_type;
        const attributes = JSON.parse(item.attributes);
        attributes.forEach(attr => {
            attr.parameters.forEach(param => {
                if (param.is_active === true && attr.id !== 'ssa') {
                    const parameterId = `${boardType}:${param.id}`;
                    const parameterName = param.custom_name ? param.custom_name : `${boardType} ${param.name}`;
                    const attributeId = attr.id;
                    _parameters.push({ id: parameterId, name: parameterName, attribute: attributeId });
                }
            });
        });
    });

    return _parameters;
};

const getParametersLegacyDevices = (device) => {
    let _parameters = [];

    legacyParameters[device.type].forEach(item => {
        _parameters.push({ id: item.id, name: item.name });
    });

    return _parameters;
};

const extractV2DevicesParameters = (data, device) => {
    const _parameters = [];
    const _selectedDeviceParameters = data.find(item => item.device === device.id);
    _selectedDeviceParameters.config.parameters.forEach(param => {
        const parameterId = param.id;
        const parameterName = param.custom_name ? param.custom_name : param.name;
        _parameters.push({ 'id': parameterId, 'name': parameterName });
    });
    return _parameters;
};


export default useDeviceParameters;
