import { useState, useEffect } from 'react';
import axios from 'axios';
import { apiEndpoints } from '../config/Endpoints';
import { useNotification } from '../components/context/NotificationContext';


const useFlows = () => {
    const [flows, setFlows] = useState([]);
    const { showMessage } = useNotification();

    useEffect(() => {
        axios
            .get(apiEndpoints.flows, { withCredentials: true })
            .then((res) => {
                const flowData = formatFlowData(res.data.results);
                setFlows(flowData);
            })
            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    showMessage('Security Credentials Insufficient', 'error');
                }
                console.error('Error fetching flow data:', error);
            });
    }, []);

    return flows;
}

const formatFlowData = (data) => {
    return data.map((flow) => ({
        ...flow,
        flow_type: flow.flow_type,
    }));
};

export default useFlows;
