import React, { useState, useEffect, useCallback, useRef } from 'react';
import $ from 'jquery'

import Cookies from 'js-cookie';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import "./Chart.css"
import { useNotification } from '../../context/NotificationContext';

am4core.useTheme(am4themes_animated);

const SensorChart = ({
    chart_id,
    sensor_id,
    sensor_name,
    reporting_period,
    data_type,
    reportSetting=null,
    setReportSetting=null,
    setShowRange=null,
    rangeUpdate=false
}) => {
    const [chartExists, setChartExists] = useState(false);
    // State for loading status
    const [loading, setLoading] = useState(false);
    // State for sensor data
    const [sensorData, setSensorData] = useState([]);
    // State for sensor reporting settings
    const [sensorSettings, setSensorSettings] = useState({});
    const chartContainerId = `chartdiv-${chart_id}`;

    const chartRef = useRef(null);
    const sensorDataRef = useRef(sensorData);
    const sensorSettingsRef = useRef(sensorSettings);
    const { showMessage } = useNotification();

    useEffect(() => {
        sensorDataRef.current = sensorData;
    }, [sensorData]);

    useEffect(() => {
        sensorSettingsRef.current = sensorSettings;
    }, [sensorSettings]);

    // Get tenant, use to create API endpoint
    const tenant = window.location.hostname.split(".")[0]
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const baseUrl = apiUrl.replace("*", tenant)

    // Calculate start and end dates
    const calculateDateRange = (hours) => {
        const endDate = new Date();
        const startDate = new Date(endDate.getTime() - hours * 60 * 60 * 1000);
        return {
            start: startDate.toISOString().replace('T', '-').slice(0, 19),
            end: endDate.toISOString().replace('T', '-').slice(0, 19)
        };
    };

    // Make api request to sensor history and reporting settings endpoints
    const fetchSensorData = useCallback(async () => {

        if (!data_type) {
            setSensorData([]);

            if (setShowRange != null) {
                setShowRange(false);
            }
            return;
        }

        // Define fetchSensorReportSettings inside useCallback
        const fetchSensorReportSettings = async (sensorId) => {
            try {
                const csrfToken = Cookies.get('csrftoken');
                const url = `${baseUrl}/api/sensor_report_setting/${sensorId}`;
                const response = await $.ajax({
                    url: url,
                    method: 'GET',
                    xhrFields: {
                        withCredentials: true
                    },
                    headers: {
                        'X-CSRFToken': csrfToken,
                    },
                    contentType: 'application/json',
                });
                return response;
            } catch (error) {
                console.error('Error fetching sensor report settings:', error);
                if (error.status === 403) {
                    showMessage('Security Credentials Insufficient', 'error');
                }
                throw error;
            }
        };

        try {
            const { start, end } = calculateDateRange(reporting_period);
            const queryParams = new URLSearchParams({
                data_type: data_type,
                start_date: start,
                end_date: end,
            }).toString();
            const dataUrl = `${baseUrl}/api/v2/sensor_history/${sensor_id}/data?${queryParams}`;

            const csrfToken = Cookies.get('csrftoken');
            // Fetching sensor data and report settings in parallel
            const [sensorDataResponse, sensorReportSettings] = await Promise.all([
                $.ajax({
                    url: dataUrl,
                    method: 'GET',
                    // No Authorization header needed, as cookies are automatically sent
                    xhrFields: {
                        withCredentials: true
                    },
                    headers: {
                        'X-CSRFToken': csrfToken,
                    },
                    contentType: 'application/json',
                }),
                fetchSensorReportSettings(sensor_id)
            ]);
            const newSensorData = sensorDataResponse.map(item => ({
                history_date: item.history_date,
                temperature: item.temperature,
                humidity: item.humidity,
                pressure: item.pressure,
                gas_resistance: item.gas_resistance,
                battery: item.battery
            }));
            const newSensorSettings = sensorReportSettings;

            // Compare new data with current state before updating
            if (JSON.stringify(newSensorData) !== JSON.stringify(sensorDataRef.current)) {
                setSensorData(newSensorData);
            }

            if (JSON.stringify(newSensorSettings) !== JSON.stringify(sensorSettingsRef.current)) {
                setSensorSettings(newSensorSettings);
                if (reportSetting) {
                    setReportSetting(newSensorSettings);
                }
            }
        } catch (error) {
            // TODO: The api is throwing 500 when invalid data type is provided, it should throw 400 Bad request
            console.error('Error in fetching data:', error);
            if (error.status === 403) {
                showMessage('Security Credentials Insufficient', 'error');
            }
        } finally {
            setLoading(false);
        }
    }, [sensor_id, reporting_period, data_type, baseUrl, rangeUpdate]);

    // useEffect hook for fetching all necessary sensor data
    useEffect(() => {
        fetchSensorData();
        const intervalId = setInterval(fetchSensorData, 15000); // Setup interval for refreshing every 15 seconds
        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [fetchSensorData]);


    // useEffect hook for rendering the chart
    useEffect(() => {
        // Don't create the chart if there is no data
        if (!sensorData.length) {
            setChartExists(false);
            return;
        }

        setChartExists(true);

        function renderLineChart(rawData) {
            // Transform data to fit amCharts requirements
            const data = rawData.map(item => {
                // Convert the `history_date` string to a Date object
                const date = new Date(item.history_date);
                // Format the date to only show the time in hours and minutes
                const formattedTime = date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ':' + date.getSeconds().toString().padStart(2, '0');
                // Determine which attribute to use for 'state'
                let state;
                if (item.temperature !== undefined) {
                    state = item.temperature;
                } else if (item.humidity !== undefined) {
                    state = item.humidity;
                } else if (item.battery !== undefined) {
                    state = item.battery;
                } else if (item.pressure !== undefined) {
                    state = item.pressure;
                } else if (item.gas_resistance !== undefined) {
                    state = item.gas_resistance;
                }

                return {
                    dateStr: formattedTime,
                    state: state,
                };
            });

            data.reverse()

            // Define chart and data
            let chart = am4core.create(chartContainerId, am4charts.XYChart);
            chartRef.current = chart;
            chart.data = data;
            chart.logo.disabled = true
            chart.paddingTop = 0;
            chart.paddingBottom = 0;
            chart.paddingLeft = 0;
            chart.paddingRight = 20;

            // Define category axis
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "dateStr";
            categoryAxis.renderer.minGridDistance = 50;
            categoryAxis.renderer.grid.template.location = 0.5;
            categoryAxis.renderer.labels.template.rotation = 45;
            categoryAxis.startLocation = 0.5;
            categoryAxis.endLocation = 0.5;

            // Rotate the date labels on the x-axis
            categoryAxis.renderer.labels.template.rotation = 270;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.verticalCenter = "middle";
            categoryAxis.renderer.labels.template.fill = am4core.color("#000000");

            // Define value axis
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.labels.template.fill = am4core.color("#000000"); // Set label color to black

            const minValue = sensorSettings[`${data_type}_range_min`];
            const maxValue = sensorSettings[`${data_type}_range_max`];
            valueAxis.min = minValue;
            valueAxis.max = maxValue;
            if (setShowRange != null) {
                setShowRange(true);
            }

            // Create a line series
            let series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.categoryX = "dateStr";
            series.dataFields.valueY = "state";
            series.tooltipText = "{state}";
            series.strokeWidth = 2;
            series.tensionX = 0.77;

            var range = valueAxis.createSeriesRange(series);
            range.value = 0;
            range.endValue = 1000;
            range.contents.stroke = am4core.color("#FF0000");
            range.contents.fill = range.contents.stroke;
            chart.cursor = new am4charts.XYCursor();

            chart.cursor = new am4charts.XYCursor();

            // Scrollbar (optional)
            chart.scrollbarX = new am4core.Scrollbar();
            return () => {
                if (chartRef.current) {
                    chartRef.current.dispose();
                    chartRef.current = null;
                }
            };
        }
        renderLineChart(sensorData, sensorSettings)

    }, [sensorData, data_type, sensorSettings, reporting_period, sensor_id, chartContainerId, chartExists]);

    return (
        <div className='widget-container' style={{ height: "100%" }}>
            <div className='widget-title'>{sensor_name}</div>
            {
                loading ? <p>Loading...</p> :
                (
                    !chartExists ?  <div className="chart-not-exist">No Data Found!</div> :
                    <div id={chartContainerId} className="chart-container"></div>
                )
            }
        </div>
    );
};

export default SensorChart;