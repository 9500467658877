import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Switch from '@mui/material/Switch';

import './SwitchDevice.css';
import { useNotification } from '../../context/NotificationContext';

const SwitchDevice = ({ device_id, device_name }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(true);

    // Get tenant, use to create API endpoint
    const tenant = window.location.hostname.split(".")[0]
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const baseUrl = apiUrl.replace("*", tenant)
    const { showMessage } = useNotification();

    useEffect(() => {
        const fetchDeviceState = async () => {
            try {
                const response = await $.ajax({
                    url: `${baseUrl}/api/device_state/${device_id}/`,
                    method: 'GET',
                    xhrFields: {
                        withCredentials: true
                    },
                });
                setIsChecked(response.state === "ON");
            } catch (error) {
                console.error('Error fetching device state.', error);
                if (error.status === 403) {
                    showMessage('Security Credentials Insufficient', 'error');
                }
            } finally {
                setLoading(false); // Set loading to false when the fetch is complete or fails
            }
        };

        fetchDeviceState(); // Fetch immediately on mount

        const intervalId = setInterval(fetchDeviceState, 15000); // Setup interval for refreshing every 15 seconds

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [device_id, baseUrl]); // Dependencies

    return (
        <fieldset className="widget-container" style={{ alignItems: "center", justifyContent: "center" }} >
            <div className="widget-title">{device_name}</div>
            <Switch
                checked={isChecked}
                onChange={() => { }}
                name="lightSwitch"
                color="primary"
                disabled={loading} // Optionally disable the switch while loading
                className='custom-switch'
            />
            {loading && <div className='loading'>Loading...</div>}
        </fieldset >
    );
};

export default SwitchDevice;
