import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { appEndpoints } from '../config/Endpoints';
import { useNotification } from '../components/context/NotificationContext';


const useFetchUnpaginatedItems = (endpoint, refetchIndex) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { showMessage } = useNotification();

    useEffect(() => {
        const fetchEndpoint = endpoint

        fetch(fetchEndpoint, {
            method: 'GET',
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                if (response.status === 401) {
                    navigate(appEndpoints.login)
                }
                if (response.status === 403) {
                    showMessage('Security Credentials Insufficient', 'error');
                }
                throw new Error(`Failed to fetch data from ${endpoint}`);
            }
        }).then(data => {
            setData(data);
        }).catch(error => {
            throw new Error(error.message);
        });

    }, [endpoint, refetchIndex]);

    return { data };
};

export default useFetchUnpaginatedItems;
