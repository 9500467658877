import React, { useState, useEffect, useCallback, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "./Chart.css";
import { useNotification } from '../../context/NotificationContext';
import { zeroboardCsaParamMapping } from '../../../config/Parameters';
am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_dark);

const DeviceChart = ({
    chart_id,
    device_id,
    device_name,
    device_type,
    reporting_period,
    board_type,
    attribute,
    parameter,
    reportSetting=null,
    setReportSetting=null,
    rangeUpdate=false,
    showTimeOnly=false,
}) => {
    const [chartExists, setChartExists] = useState(false);
    // State for loading status
    const [loading, setLoading] = useState(false);
    const [deviceData, setDeviceData] = useState([]);
    const [deviceReportSettings, setDeviceReportSettings] = useState({});
    const chartRef = useRef(null);
    const chartContainerId = `chartdiv-${chart_id}`;
    const { showMessage } = useNotification();

    // Get tenant, use to create API endpoint
    const tenant = window.location.hostname.split(".")[0]
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const baseUrl = apiUrl.replace("*", tenant)

    const defaultBarValue = 0;
    const stateToBarValueMap = {
        0: 1,
        1: 6,
        "ON": 6,
        "COOLING": 6,
        "HEATING": 5,
        "OFF": 1,
        "AUTO": 2
    };
    const defaultBarColor = "#741210";
    const valueToBarColorMap = {
        6: "#056210",
        5: "#FFA500",
        2: "#FFA500"
    };

    const formatDate = (date_field) => {
        if (showTimeOnly) {
            return date_field.split("T")[1]
        }
        else {
            return date_field.replace("T", " ")
        }
    }

    const deviceDataRef = useRef(deviceData);

    useEffect(() => {
      deviceDataRef.current = deviceData;
    }, [deviceData]);

    const deviceReportSettingsRef = useRef(deviceReportSettings);

    useEffect(() => {
      deviceReportSettingsRef.current = deviceReportSettings;
    }, [deviceReportSettings]);

    const processData = (data) => {
        switch (device_type) {
            case 'Sensor':
                return data.map(item => ({
                    state: item.fields.values[parameter],
                    history_date: formatDate(item.fields.history_date)
                }));
                break;
            case 'Outlet':
            case 'Thermostat':
                return data.filter(
                    item => item.fields.update_parameter === parameter
                ).map(item => ({
                    state: item.fields[parameter],
                    history_date: formatDate(item.fields.history_date)
                }));
                break;
            default:
                return data.map(item => ({
                    state:
                        (device_type === 'Node B' && attribute === 'csa') ? item.fields[zeroboardCsaParamMapping[parameter]] :
                        (parameter === 'analog_output0') ? item.fields.state / 10 :
                            (parameter === 'thermistor0' || parameter === 'thermistor1') ? item.fields.state / 100 :
                                item.fields.state,
                    history_date: formatDate(item.fields.history_date)
                }));
        }
    };

    const fetchDeviceData = useCallback(async () => {
        try {
            if (!device_type || !parameter) {
                setDeviceData([]);
                return;
            }

            let queryParams = new URLSearchParams({
                id: device_id,
                reporting_period: reporting_period,
                device_type: device_type
            });

            if (device_type === "Node B") {
                queryParams.append('board_type', board_type);
                queryParams.append('attribute', attribute);
                queryParams.append('parameter', parameter);
            }

            const dataUrl = `${baseUrl}/api/v2/device_history/${device_id}/data?${queryParams.toString()}`;

            const fetchDeviceReportData = fetch(dataUrl, {
                method: 'GET',
                credentials: 'include'
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 403) {
                        showMessage('Security Credentials Insufficient', 'error');
                    }
                    throw new Error('Failed to fetch device data');
                }
            });

            let promises = [];

            // Always fetch device data
            promises.push(fetchDeviceReportData);

            // Conditionally fetch report settings
            if (device_type === 'Sensor') {
                const fetchReportSetting = fetch(`${baseUrl}/api/v2/report/setting/${device_id}`, {
                    method: 'GET',
                    credentials: 'include'
                }).then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        if (response.status === 403) {
                            showMessage('Security Credentials Insufficient', 'error');
                        }
                        throw new Error('Failed to fetch report setting');
                    }
                });
                promises.push(fetchReportSetting);
            }

            Promise.all(promises)
                .then(results => {
                    const [responseData, deviceReportSettingData] = results;

                    let processedData = processData(responseData);
                    if (JSON.stringify(processedData) !== JSON.stringify(deviceDataRef.current)) {
                        setDeviceData(processedData);
                    }
                    if (
                        device_type === 'Sensor' &&
                        JSON.stringify(deviceReportSettingData.setting) !== JSON.stringify(deviceReportSettingsRef.current)
                    ) {
                        setDeviceReportSettings(deviceReportSettingData.setting);
                        if (reportSetting) {
                            setReportSetting(deviceReportSettingData.setting);
                        }
                    }
                })
                .catch(error => {
                    console.error("Fetch error: ", error);
                });
        }

        catch (error) { console.error('Error in fetching data:', error); }

        finally { setLoading(false); }

    }, [device_id, device_type, reporting_period, board_type, attribute, parameter, baseUrl, rangeUpdate]);


    // Periodically fetch data every 15 seconds
    useEffect(() => {
        fetchDeviceData();
        const intervalId = setInterval(fetchDeviceData, 15000);
        return () => clearInterval(intervalId);
    }, [fetchDeviceData]);

    // Render the chart
    useEffect(() => {
        function renderBarChart(rawData) {
            // Transform data to fit amCharts input
            const data = rawData.map(item => {
                let value;

                value = stateToBarValueMap[item.state] || defaultBarValue;

                return {
                    dateStr: item.history_date,
                    state: item.state,
                    value: value,
                };
            });

            data.reverse();

            let chart = am4core.create(chartContainerId, am4charts.XYChart);
            chartRef.current = chart;
            chart.data = data;
            chart.logo.disabled = true
            chart.paddingTop = 0;
            chart.paddingBottom = 0;
            chart.paddingLeft = 20;
            chart.paddingRight = 20;

            // Define category axis
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "dateStr";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 50;

            // Rotate the date labels on the x-axis
            categoryAxis.renderer.labels.template.rotation = 270;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.verticalCenter = "middle";

            // Change the color of the date axis text to black
            categoryAxis.renderer.labels.template.fill = am4core.color("#000000");

            // Define value axis
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            if (valueAxis.tooltip) {
                valueAxis.tooltip.disabled = true;
            }

            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.categoryX = "dateStr";
            series.dataFields.valueY = "value";
            series.tooltipText = "{state}";

            // Styling
            series.columns.template.width = am4core.percent(80);
            series.columns.template.strokeOpacity = 0;
            valueAxis.renderer.labels.template.disabled = true;
            series.columns.template.cornerRadiusTopLeft = 10;
            series.columns.template.cornerRadiusTopRight = 10;
            series.columns.template.adapter.add("fill", function (fill, target) {
                if (target.dataItem && target.dataItem.dataContext) {
                    let value = target.dataItem.dataContext.value;
                    return am4core.color(valueToBarColorMap[value] || defaultBarColor);
                }
                return fill;
            });
            chart.cursor = new am4charts.XYCursor();
            chart.scrollbarX = new am4core.Scrollbar();

            return () => {
                if (chartRef.current) {
                    chartRef.current.dispose();
                    chartRef.current = null;
                }
            };
        }

        // Line chart rendering function
        function renderLineChart(rawData) {
            // Transform data to fit amcharts input
            const data = rawData.map(item => {
                return {
                    dateStr: item.history_date,
                    state: item.state,
                }
            });

            data.reverse()

            // Define chart and data
            let chart = am4core.create(chartContainerId, am4charts.XYChart);
            chart.data = data;
            chart.logo.disabled = true
            chart.paddingTop = 0;
            chart.paddingBottom = 0;
            chart.paddingLeft = 0;
            chart.paddingRight = 20;

            // Define category axis
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "dateStr";
            categoryAxis.renderer.minGridDistance = 50;
            categoryAxis.renderer.grid.template.location = 0.5;
            categoryAxis.renderer.labels.template.rotation = 270;
            categoryAxis.startLocation = 0.5;
            categoryAxis.endLocation = 0.5;

            // Rotate the date labels on the x-axis
            categoryAxis.renderer.labels.template.rotation = 270;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.verticalCenter = "middle";
            categoryAxis.renderer.labels.template.fill = am4core.color("#000000");

            // Define value axis
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.labels.template.fill = am4core.color("#000000");

            if (device_type === 'Sensor') {
                const minValue = deviceReportSettings[parameter]['min'];
                const maxValue = deviceReportSettings[parameter]['max'];
                valueAxis.min = minValue;
                valueAxis.max = maxValue;

            } else {
            }

            // Create a line series
            let series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.categoryX = "dateStr";
            series.dataFields.valueY = "state";
            series.tooltipText = "{state}";
            series.strokeWidth = 2;
            series.tensionX = 0.77;
            var range = valueAxis.createSeriesRange(series);
            range.value = 0;
            range.endValue = 1000;
            range.contents.stroke = am4core.color("#FF0000");
            range.contents.fill = range.contents.stroke;
            chart.cursor = new am4charts.XYCursor();

            // Scrollbar
            chart.scrollbarX = new am4core.Scrollbar();

            return () => {
                if (chartRef.current) {
                    chartRef.current.dispose();
                    chartRef.current = null;
                }
            };
        }

        // Exit the hook if no data is available
        if (!deviceData.length) {
            setChartExists(false);
            return;
        }
        setChartExists(true);
        // Determine if line or bar chart is needed
        if (device_type === "Switch" || device_type === "DIN Rail Switch" || device_type === "Battery Sensor" ||
            device_type === "Motion Sensor" || device_type === "Open Closed Sensor" || device_type === "Smoke Bridge"
            || device_type === "Outlet" || device_type === "IOLinc" || parameter === 'status_state' || parameter
            === 'fan_state' || (parameter && parameter.substring(0, 5) === 'relay') || (parameter && parameter.substring(0, 18)
                === 'open_drain_output') || (parameter && parameter.substring(0, 13) === 'digital_input') || parameter === 'gpio'
            || (parameter && parameter.substring(0, 13) === 'triac_output') || (attribute === 'csa' && parameter === 'rs')
            || (parameter === 'open_close')) {

            renderBarChart(deviceData);
        }
        else {
            renderLineChart(deviceData)
        }
    }, [deviceData, chartContainerId, chartExists, deviceReportSettings, parameter]);

    return (
        <div className='widget-container' style={{ height: "100%" }}>
            <div className='widget-title'>{device_name}</div>
            {
                loading ? <p>Loading...</p> :
                (
                    !chartExists ?  <div className="chart-not-exist">No Data Found!</div> :
                    <div id={chartContainerId} className="chart-container"></div>
                )
            }
        </div>
    );
};

export default DeviceChart;
