// React and third-party libraries
import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  TextField,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'

// Components
import DataTable from '../general/DataTable';
import Wizard from '../general/Wizard';
import MultiSelectDropdown from '../general/MultiSelectDropdown';

// Configurations
import {apiEndpoints, appEndpoints} from '../../config/Endpoints';

// Data hooks
import useFetchItems from '../../hooks/FetchItems';
import useFetchUnpaginatedItems from '../../hooks/FetchUnpaginatedItems';

//styles
import './RuleEngine.css'


const RuleEngine = () => {
    const [error, setError] = useState({});

    const [usersDropdownData, setUsersDropdownData] = useState([]);
    const [refetchIndex, setRefetchIndex] = useState(0);

    const [isWizardOpen, setWizardOpen] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const [name, setName] = useState('');
    const [sourceConfiguration, setSourceConfiguration] = useState([{
        device_name: '',
        board_type: "zeroboard",
        attribute: "sa",
        parameter: '',
        value: '',
        operator: '',
        logical_operator: 'None'
    }]);
    const [timeConfiguration, setTimeConfiguration] = useState([]);
    const [actionConfiguration, setActionConfiguration] = useState([]);
    const [alertConfiguration, setAlertConfiguration] = useState({});

    const [newData, setNewData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const endpoint = apiEndpoints.ruleEngineEndpoint;
    const columns = [
        {
            name: "name",
            label: "Name",
        },
    ];

    const userEndpoint = apiEndpoints.userManagementEndpoint;
    const { data: usersData, count: usersCount } = useFetchItems(userEndpoint, 0, 1000, refetchIndex);

    const ruleEngineDevicesDataEndpoint = apiEndpoints.ruleEngineDevicesDataEndpoint;
    const { data: ruleEngineDevicesData } = useFetchUnpaginatedItems(ruleEngineDevicesDataEndpoint, refetchIndex);
    const [sourceSelectedDevices, setSourceSelectedDevices] = useState({});
    const [actionSelectedDevices, setActionSelectedDevices] = useState({});

    var timeConfigDays = [
        {
            id: 1,
            name: 'MON',
            selected: false
        },
        {
            id: 2,
            name: 'TUE',
            selected: false,
        },
        {
            id: 3,
            name: 'WED',
            selected: false,
        },
        {
            id: 4,
            name: 'THU',
            selected: false,
        },
        {
            id: 5,
            name: 'FRI',
            selected: false,
        },
        {
            id: 6,
            name: 'SAT',
            selected: false,
        },
        {
            id: 7,
            name: 'SUN',
            selected: false,
        },
        {
            id: 8,
            name: 'ALL',
            selected: false,
        },
    ]

    useEffect(() => {
        setUsersDropdownData(
            usersData.map(user => ({
                id: user.id,
                name: user.username,
                selected: false
            }))
        )
    }, [usersData]);

    const resetWizard = () => {
        setName('');
        setError({});
        setSourceConfiguration([{
            device_name: '',
            board_type: "zeroboard",
            attribute: "sa",
            parameter: '',
            value: '',
            operator: '',
            logical_operator: 'None'
        }]);
        setTimeConfiguration([]);
        setActionConfiguration([]);
        setAlertConfiguration({});
    };

    const populateFields = (data) => {
        setName(data.name);
        setSourceConfiguration(data.source_configuration);
        setTimeConfiguration(data.time_configuration);
        setActionConfiguration(data.action_configuration);
        setAlertConfiguration(data.alert_configuration);

        data.source_configuration.forEach(item => {
            const deviceName = item.device_name;
            const paramData = ruleEngineDevicesData.find(device => device.device_name === deviceName);

            setSourceSelectedDevices(prevState => ({
                ...prevState,
                [deviceName]: paramData
            }));
        });

        data.action_configuration.forEach(item => {
            const deviceId = item.device_id;
            const paramData = ruleEngineDevicesData.find(device => device.device_id === deviceId);

            setActionSelectedDevices(prevState => ({
                ...prevState,
                [deviceId]: paramData
            }));
        });
    };

    const handleOpen = () => {
        setIsSubmit(false);
        setIsViewMode(false);
        setIsEditMode(false);
        resetWizard();
        setWizardOpen(true);
    };

    const handleClose = () => {
        resetWizard();
        setWizardOpen(false);
    };

    const handleFinish = () => {
        if (isViewMode){
            setWizardOpen(false);
            return;
        }
        if (!validateData()){
            setError({"detail": "Missing required fields!"});
            return;
        }

        const data = {
            name: name,
            source_configuration: sourceConfiguration,
            time_configuration: timeConfiguration,
            action_configuration: actionConfiguration,
            alert_configuration: alertConfiguration
        };
        setNewData(data);
        setIsSubmit(true);
    };

    const handleViewOpen = (data) => {
        setIsSubmit(false);
        populateFields(data);
        setIsEditMode(true);
        setIsViewMode(true);
        setWizardOpen(true);
    };

    const handleEditOpen = (data) => {
        setIsSubmit(false);
        populateFields(data);
        setIsViewMode(false);
        setIsEditMode(true);
        setWizardOpen(true);
    };

    const handleSourceConfigurationChange = (index, field, value) => {
        if (field === 'value') {
            value = Number(value)
        }

        const updatedSources = sourceConfiguration.map((source, i) => 
            i === index ? { ...source, [field]: value } : source
        );
        setSourceConfiguration(updatedSources);

        if (field === 'device_name') {
            setSourceSelectedDevices(prevState => ({
                ...prevState,
                [value]: ruleEngineDevicesData.find(device => device.device_name === value)
            }));
        }
    };

    const addSource = () => {
        setSourceConfiguration(sourceConfiguration.concat({
            device_name: '',
            board_type: "zeroboard",
            attribute: "sa",
            parameter: '',
            value: '',
            operator: '',
            logical_operator: 'None'
        }));
    };

    const removeSource = (index) => {
        setSourceConfiguration(sourceConfiguration.filter((_, i) => i !== index));
    };

    const handleActionChange = (index, field, value) => {
        if (field === 'value') {
            value = Number(value)
        }

        const updatedActions = actionConfiguration.map((action, i) => 
            i === index ? { ...action, [field]: value } : action
        );
        setActionConfiguration(updatedActions);

        if (field === 'device_id') {
            setActionSelectedDevices(prevState => ({
                ...prevState,
                [value]: ruleEngineDevicesData.find(device => device.device_id === value)
            }));
        }
    };

    const addAction = () => {
        setActionConfiguration(actionConfiguration.concat({ device_id: '', parameter: '', value: '' }));
    };

    const removeAction = (index) => {
        setActionConfiguration(actionConfiguration.filter((_, i) => i !== index));
    };

    const handleIsActionChange  = (value) => {
        if(value) {
            addAction()
        } else {
            setActionConfiguration([])
        }
        
    };

    const handleIsAlertChange  = (value) => {
        if(value) {
            setAlertConfiguration({ is_send_email: false, is_send_sms: false, alert_text: '', users: [] });
        } else {
            setAlertConfiguration([])
        }
        
    };

    const handleAlertText = (value) => {
        setAlertConfiguration(prevConfig => ({
            ...prevConfig,
            alert_text: value
        }));
    };

    const handleIsSendSms = (value) => {
        setAlertConfiguration(prevConfig => ({
            ...prevConfig,
            is_send_sms: value
        }));
    };

    const handleIsSendEmail = (value) => {
        setAlertConfiguration(prevConfig => ({
            ...prevConfig,
            is_send_email: value
        }));
    };

    const handleUsers = (event) => {
        const {
          target: { value },
        } = event;

        setAlertConfiguration(prevConfig => ({
            ...prevConfig,
            users: value
        }));
    };

    const handleDaysOfWeek = (event) => {
        const {
          target: { value },
        } = event;

        const updatedTimeConfiguration = timeConfiguration.map((config, i) => 
            i === 0 ? { ...config, 'days_of_week': value.includes("ALL") ? ["ALL"] : value } : config
        );
        setTimeConfiguration(updatedTimeConfiguration);
    };

    const handleStartTime = (value) => {

        const updatedTimeConfiguration = timeConfiguration.map((config, i) => 
            i === 0 ? { ...config, 'start_time': getTimeSeconds(value) } : config
        );
        setTimeConfiguration(updatedTimeConfiguration);
    };

    const handleEndTime = (value) => {
        const updatedTimeConfiguration = timeConfiguration.map((config, i) => 
            i === 0 ? { ...config, 'end_time': getTimeSeconds(value) } : config
        );
        setTimeConfiguration(updatedTimeConfiguration);
    };

    const handleTimeConfigChange = (value) => {
        if(value) {
            setTimeConfiguration([{ days_of_week: [], start_time: 0, end_time: 0}]);
        } else {
            setTimeConfiguration([])
        }
        
    };

    const getTimeSeconds = (time) => {
        console.log(time)
        const date = new Date(time);
        console.log(date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds())
        return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
    };

    const getTimeFromSeconds = (seconds) => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setSeconds(seconds);
        return date
    };

    const validateData = () => {
        if (name.trim() === '') return false;

        for (const item of sourceConfiguration) {
            if (item.device_name.trim() === '') return false;
            if (item.parameter.trim() === '') return false;
            if (item.operator.trim() === '') return false;
            if (item.value === '') return false;
            if (sourceConfiguration.indexOf(item) === 0 && item.logical_operator.trim() === '') return false;
        };

        for (const item of timeConfiguration) {
            if (item.end_time === 0) return false;
            if (item.days_of_week.length === 0) return false;
        };

        for (const item of actionConfiguration) {
            if (item.device_id === '') return false;
            if (item.parameter.trim() === '') return false;
            if (item.value === '') return false;
        };

        if (Object.keys(alertConfiguration ?? {}).length > 0) {
            if (alertConfiguration.alert_text.trim() === '' || alertConfiguration.users.length === 0) return false;
        }

        return true;
    };

    const steps = [
      {
        label: 'Basic Information',
        content: () => (
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="h6" sx={{ mb: 10 }}>
                        Enter Rule Chain Basic Information
                    </Typography>
                    <Box marginBottom={2}>
                        <TextField
                            label="Rule Chain Name"
                            fullWidth
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            disabled={isViewMode, isEditMode}
                        />
                    </Box>
                </Grid>
            </Grid>
        )
      },
      {
        label: 'Source Configuration',
        content: () => (
            sourceConfiguration.map((source, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                    <Grid container spacing={2}>
                        {index > 0 && (
                          <Grid item xs={12} style={{ textAlign: 'center' }}>
                              <Box width={1/4} mx="auto">
                                  <FormControl fullWidth>
                                      <InputLabel id={`logical-operator-label-${index}`}>Logical Operator</InputLabel>
                                      <Select
                                          labelId={`logical-operator-label-${index}`}
                                          id={`logical-operator-select-${index}`}
                                          value={source.logical_operator}
                                          label="Logical Operator"
                                          onChange={(e) => handleSourceConfigurationChange(index, 'logical_operator', e.target.value)}
                                      >
                                          <MenuItem value=""><em>Select Logical Operator</em></MenuItem>
                                          <MenuItem value="AND">AND</MenuItem>
                                          <MenuItem value="OR">OR</MenuItem>
                                      </Select>
                                  </FormControl>
                              </Box>
                          </Grid>
                        )}
                        <Grid item xs={6}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id={`device-label-${index}`}>Device</InputLabel>
                            <Select
                                labelId={`device-label-${index}`}
                                id={`device-select-${index}`}
                                value={source.device_name}
                                onChange={(e) => handleSourceConfigurationChange(index, 'device_name', e.target.value)}
                                label="Device"
                                required
                                disabled={isViewMode}
                            >
                                {ruleEngineDevicesData.map(device => (
                                    <MenuItem key={device.device_id} value={device.device_name}>
                                        {device.device_name}
                                    </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id={`parameter-label-${index}`}>Parameter</InputLabel>
                                <Select
                                  labelId={`parameter-label-${index}`}
                                  id="parameter-select"
                                  value={source.parameter}
                                  onChange={(e) => handleSourceConfigurationChange(index, 'parameter', e.target.value)}
                                  label="Parameter"
                                  required
                                  disabled={isViewMode}
                                >
                                    {source.device_name in sourceSelectedDevices && sourceSelectedDevices[source.device_name].rule_engine_params.map(param => (
                                        <MenuItem key={param.id} value={param.id}>
                                            {param.custom_name ? param.custom_name : param.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id={`condition-label-${index}`}>Condition</InputLabel>
                                <Select labelId={`condition-label-${index}`}
                                  id="condition-select"
                                  value={source.operator}
                                  onChange={(e) => handleSourceConfigurationChange(index, 'operator', e.target.value)}
                                  label="Condition"
                                  required
                                  disabled={isViewMode}
                                >
                                    <MenuItem value=""><em>Select condition</em></MenuItem>
                                    <MenuItem value="==">Equal</MenuItem>
                                    <MenuItem value=">">Greater</MenuItem>
                                    <MenuItem value="<">Less</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Value"
                              type="number"
                              fullWidth
                              variant="outlined"
                              value={source.value}
                              onChange={(e) => handleSourceConfigurationChange(index, 'value', e.target.value)}
                              required 
                              disabled={isViewMode}

                            />
                        </Grid>
                    </Grid>
                    <div key={index} className="field-sets-action">
                        {sourceConfiguration.length > 1 && (
                            <Button
                                variant="outlined"
                                onClick={() => removeSource(index)}
                                color="error"
                                startIcon={<DeleteIcon />}
                                style={{ marginRight: '10px' }}
                            >
                                Remove
                            </Button>
                        )}

                        {index === sourceConfiguration.length - 1 && (
                            <Button
                                variant="outlined"
                                onClick={addSource}
                                color="primary" // Or "success" for green as per your theme
                                startIcon={<AddIcon />}
                                disabled={isViewMode}
                            >
                                Add
                            </Button>
                        )}
                    </div>
                </Box>
            ))
        )
      },
      {
        label: 'Time Window',
        content: () => (
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="h6" sx={{ mb: 10 }}>
                        Enter time window details
                    </Typography>
                    <Box marginBottom={2}>
                        <FormControlLabel
                            control={
                                    <Checkbox
                                        checked={timeConfiguration.length}
                                        onChange={(e) => handleTimeConfigChange(e.target.checked)}
                                    />
                            }
                            label="Add Time Window during which the New Rule will be active?"
                            disabled={isViewMode}
                        />
                    </Box>
                    {timeConfiguration.length != 0 && (
                        <Box marginBottom={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileTimePicker
                                    label="Start Time"
                                    value={getTimeFromSeconds(timeConfiguration[0].start_time)}
                                    onChange={handleStartTime}
                                    width="100%"
                                    disabled={isViewMode}
                                    fullWidth
                                />
                            </LocalizationProvider>
                        </Box>
                    )}
                    {timeConfiguration.length != 0 && (
                        <Box marginBottom={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileTimePicker
                                    label="End Time"
                                    value={getTimeFromSeconds(timeConfiguration[0].end_time)}
                                    onChange={handleEndTime}
                                    width="100%"
                                    disabled={isViewMode}
                                    fullWidth
                                />
                            </LocalizationProvider>
                        </Box>
                    )}
                    {timeConfiguration.length != 0 && (
                        <>
                            <Box marginBottom={2}>
                                <MultiSelectDropdown
                                    label="Select Days"
                                    disabled={isViewMode}
                                    itemsData={timeConfigDays}
                                    selectedItems={timeConfiguration[0].days_of_week}
                                    handleChange={handleDaysOfWeek}
                                />
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
        )
      },
      {
        label: 'Action Configuration',
        content: () => (
            <>
                <Box marginBottom={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={actionConfiguration.length}
                                onChange={(e) => handleIsActionChange(e.target.checked)}
                            />
                        }
                        label="Trigger action?"
                        disabled={isViewMode}
                    />
                </Box>
                {actionConfiguration.length != 0 && actionConfiguration.map((action, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id={`device-label-${index}`}>Device</InputLabel>
                                    <Select labelId={`device-label-${index}`}
                                      id="device-select"
                                      value={action.device_id}
                                      onChange={(e) => handleActionChange(index, 'device_id', e.target.value)}
                                      label="Device"
                                      required
                                      disabled={isViewMode}
                                    >
                                        {ruleEngineDevicesData.map(device => (
                                            <MenuItem key={device.device_id} value={device.device_id}>
                                                {device.device_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id={`parameter-label-${index}`}>Parameter</InputLabel>
                                    <Select labelId={`parameter-label-${index}`}
                                      id="parameter-select"
                                      value={action.parameter}
                                      onChange={(e) => handleActionChange(index, 'parameter', e.target.value)}
                                      label="Parameter"
                                      required
                                      disabled={isViewMode}
                                    >
                                        {action.device_id in actionSelectedDevices && actionSelectedDevices[action.device_id].rule_engine_params.map(param => (
                                            <MenuItem key={param.id} value={param.id}>
                                                {param.custom_name ? param.custom_name : param.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label="Value"
                                  type="number"
                                  fullWidth
                                  variant="outlined"
                                  value={action.value}
                                  onChange={(e) => handleActionChange(index, 'value', e.target.value)}
                                  required
                                  disabled={isViewMode}
                                />
                            </Grid>
                        </Grid>
                        <div key={index} className="field-sets-action">
                            {actionConfiguration.length > 1 && (
                                <Button
                                    variant="outlined"
                                    onClick={() => removeAction(index)}
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    style={{ marginRight: '10px' }}
                                >
                                    Remove
                                </Button>
                            )}

                            {index === actionConfiguration.length - 1 && (
                                <Button
                                    variant="outlined"
                                    onClick={addAction}
                                    color="primary"
                                    startIcon={<AddIcon />}
                                >
                                    Add
                                </Button>
                            )}
                        </div>
                    </Box>
                ))}
            </>
        )
      },
      {
        label: 'Alert Configuration',
        content: () => (
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="h6" sx={{ mb: 10 }}>
                        Enter Alert Configuration Details
                    </Typography>
                    <Box marginBottom={2}>
                        <FormControlLabel
                            control={
                                    <Checkbox
                                        checked={Object.keys(alertConfiguration).length}
                                        onChange={(e) => handleIsAlertChange(e.target.checked)}
                                    />
                            }
                            label="Enable Alert?"
                            disabled={isViewMode}
                        />
                    </Box>
                    {Object.keys(alertConfiguration).length != 0 && (
                        <>
                            <FormControlLabel
                                control={
                                        <Checkbox
                                            checked={alertConfiguration.is_send_email}
                                            onChange={(e) => handleIsSendEmail(e.target.checked)}
                                        />
                                }
                                label="Send Email Notification"
                                disabled={isViewMode}
                            />
                            <FormControlLabel
                                control={
                                        <Checkbox
                                            checked={alertConfiguration.is_send_sms}
                                            onChange={(e) => handleIsSendSms(e.target.checked)}
                                        />
                                }
                                label="Send SMS Notification"
                                disabled={isViewMode}
                            />
                            <Box marginBottom={2}>
                                <TextField
                                    label="Alert Text"
                                    fullWidth
                                    variant="outlined"
                                    value={alertConfiguration.alert_text}
                                    onChange={(e) => handleAlertText(e.target.value)}
                                    required
                                    disabled={isViewMode}

                                />
                            </Box>
                            <Box marginBottom={2}>
                                <MultiSelectDropdown
                                    label="Alert Users"
                                    itemsData={usersDropdownData}
                                    selectedItems={alertConfiguration.users}
                                    handleChange={handleUsers}
                                    disabled={isViewMode}
                                />
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
        )
      },
    ];

    return (
        <Grid container className="grid-container" spacing={2}>
            <Grid item className="rule-engine-container" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Wizard
                    open={isWizardOpen}
                    steps={steps}
                    error={error}
                    isSubmit={isSubmit}
                    handleClose={() => handleClose()}
                    handleFinish ={() => handleFinish()}
                />
                <DataTable
                    name="Rule Chain"
                    title="Rule Engine"
                    columns={columns}
                    endpoint={endpoint}
                    newData={newData}
                    isSubmit={isSubmit}
                    setIsSubmit={setIsSubmit}
                    handleOpen={() => handleOpen()}
                    handleClose={() => handleClose()}
                    handleViewOpen ={(data) => handleViewOpen(data)}
                    handleEditOpen ={(data) => handleEditOpen(data)}
                    setError={setError}
                    refetchIndex={refetchIndex}
                    setRefetchIndex={setRefetchIndex}
                />
            </Grid>
        </Grid>
    );
};

export default RuleEngine;
