const legacyParameters = {
    'Switch': [{'id': 'state', 'name': 'State'}],
    'Dimmer': [{'id': 'level', 'name': 'Level'}],
    'Thermostat': [
        {'id': 'ambient_temp', 'name': 'Ambient Temperature'},
        {'id': 'cool_sp_state', 'name': 'Cool Setpoint'},
        {'id': 'heat_sp_state', 'name': 'Heat Setpoint'},
        {'id': 'status_state', 'name': 'Status'},
        {'id': 'fan_state', 'name': 'Fan State'},
        {'id': 'humid_state', 'name': 'Humidity'},
    ],
    'Outlet': [
        {'id': 'state1', 'name': 'State 1'},
        {'id': 'state2', 'name': 'State 2'}
    ],
    'LegacySensor': [
        {'id': 'temperature', 'name': 'Temperature'},
        {'id': 'humidity', 'name': 'Humidity'},
        {'id': 'pressure', 'name': 'Pressure'},
        {'id': 'gas_resistance', 'name': 'Gas Resistance'},
        {'id': 'battery', 'name': 'Battery'},
    ]
}

const zeroboardCsaParamMapping = {
    'rs': 'radar_sensor',
    'rr': 'respiration_rate',
    'hr': 'heart_rate'
}

export { legacyParameters, zeroboardCsaParamMapping };
