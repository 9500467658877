import { useState } from 'react';
import { appEndpoints } from '../config/Endpoints';
import { useNotification } from '../components/context/NotificationContext';

import Cookies from 'js-cookie';


const useDeleteItem = (endpoint, setRefetchIndex) => {
    const { showMessage } = useNotification();

    const deleteItem = async (id) => {
        try {
            const csrfToken = Cookies.get('csrftoken');
            const response = await fetch(`${endpoint}${id}/`, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
            });
            if (response.ok) {
                setRefetchIndex(prevIndex => prevIndex + 1);
            } else {
                if (response.status === 403) {
                    showMessage('Security Credentials Insufficient', 'error');
                }
                throw new Error('Failed to delete item');
            }
        } catch (error) {
            throw new Error(error.message);
        }
    };

    return { deleteItem };
};

export default useDeleteItem;
