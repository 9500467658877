// React and third-party libraries
import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Grid,
  Tooltip,
} from '@mui/material';

import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

// Components
import DataTable from '../general/DataTable';

// Configurations
import {apiEndpoints, appEndpoints} from '../../config/Endpoints';

// Data hooks
import usePatchItem from '../../hooks/PatchItem';

// Context
import { useNotification } from '../context/NotificationContext';

//styles
import './Alert.css'


const Alert = () => {
    const { showMessage } = useNotification();
    const [error, setError] = useState({});
    const [newData, setNewData] = useState({});
    const [refetchIndex, setRefetchIndex] = useState(0);

    const endpoint = apiEndpoints.AlertEndpoint;
    const { patchItem } = usePatchItem(endpoint, setRefetchIndex, setError);

    const columns = [
        {
            name: "event",
            label: "Event",
        },
        {
            name: "source_id",
            label: "Source",
        },
        {
            name: "device",
            label: "Device",
        },
        {
            name: "acknowledged",
            label: "Acknowledged",
            options: {
                customBodyRender: (value) => {
                    return value ? (
                        <Chip 
                            label="Acknowledged" 
                            color="success" 
                            variant="outlined"
                            size="small"
                        />
                        ) : (
                        <Chip 
                            label="Unacknowledged" 
                            color="error" 
                            variant="outlined"
                            size="small"
                        />
                    );
                }
            }
        },
        {
            name: "cleared",
            label: "Cleared",
            options: {
                customBodyRender: (value) => {
                    return value ? (
                        <Chip 
                            label="Cleared" 
                            color="success" 
                            variant="outlined"
                            size="small"
                        />
                        ) : (
                        <Chip 
                            label="Uncleared" 
                            color="error" 
                            variant="outlined"
                            size="small"
                        />
                    );
                }
            }
        },
        {
            name: "created_at",
            label: "Time",
        },
        {
            name: "Actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => ({ align: 'center' }),
                setCellProps: () => ({ align: 'center' }),
                customBodyRender: (value, tableMeta) => {
                    return (
                        <>
                            <Tooltip title="Acknowledge">
                                <IconButton onClick={() => handleAcknowledgeClick(tableMeta.rowData[0])} color="primary">
                                    <DoneIcon />
                                </IconButton>
                            </Tooltip>


                            <Tooltip title="Clear">
                                <IconButton onClick={() => handleClearClick(tableMeta.rowData[0])} color="primary">
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    );
                }
            }
        }

    ];

    const handleAcknowledgeClick = async (rowId) => {
        const data = {
            acknowledged: true
        };
        await patchItem(rowId, data);
        showMessage(`Alert Acknowledged!`, 'success');
    };

    const handleClearClick = async (rowId) => {
        const data = {
            cleared: true
        };
        await patchItem(rowId, data);
        showMessage(`Alert Cleared!`, 'success');
    };

    return (
        <Grid container className="grid-container" spacing={2}>
            <Grid item className="alert-container" xs={12} sm={12} md={12} lg={12} xl={12}>
                <DataTable
                    name="Alert"
                    title="Alerts"
                    columns={columns}
                    endpoint={endpoint}
                    newData={newData}
                    setError={setError}
                    crud_action={false}
                    refetchIndex={refetchIndex}
                    setRefetchIndex={setRefetchIndex}
                />
            </Grid>
        </Grid>
    );
};

export default Alert;
