import React from 'react';
import Cookies from 'js-cookie';

import {apiEndpoints, appEndpoints} from '../../config/Endpoints';
import { useNavigate } from "react-router-dom";

import './Logout.css'

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const csrfToken = Cookies.get('csrftoken');
      const response = await fetch(apiEndpoints.logout, {
        method: 'POST',
        headers: {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        navigate(appEndpoints.login);
      } else {
        console.error('Failed to log out');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <button onClick={() => handleLogout()} className="link-button">
        Signout
    </button>
  );
};

export default Logout;
